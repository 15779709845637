import React, { useState } from "react";
import "./App.css";
import { functions, httpsCallable } from "./Firebase";

function ContactForm() {
  const [formData, setFormData] = useState({
    doctor: "",
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    agreement: false,
  });
  const [messageSent, setMessageSent] = useState(false);

  const sendContactEmail = httpsCallable(functions, "sendContactEmail");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreement) {
      alert("Please agree to the terms.");
      return;
    }

    try {
      const result = await sendContactEmail(formData);
      console.log("Email sent successfully:", result.data);
      setMessageSent(true);
    } catch (error) {
      console.error("Error sending email:", error);
    }

    // Reset form data
    setFormData({
      doctor: "",
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      agreement: false,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <select
          name="doctor"
          value={formData.doctor}
          onChange={handleInputChange}
        >
          <option value="">Select a Doctor</option>
          <option value="Doctor 1">Dr. Travis Mann</option>
          <option value="Doctor 2">Dr Jay P. Shah</option>
          <option value="Doctor 3">
            Nicole Taylor Family Nurse Practitioner
          </option>
        </select>

        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Your Name"
        />

        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Your Email"
        />

        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          pattern="[0-9]*"
          onChange={handleInputChange}
          placeholder="Your Phone Number"
        />

        <textarea
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          placeholder="Your Message"
        ></textarea>

        <label className="Disclaimer">
          <input
            type="checkbox"
            name="agreement"
            checked={formData.agreement}
            onChange={handleInputChange}
          />
          I understand and agree that any information submitted will be
          forwarded to our office by email and not via a secure messaging
          system. This form should not be used to transmit private health
          information, and we disclaim all warranties with respect to the
          privacy and confidentiality of any information submitted through this
          form.
        </label>
        <button type="submit">Send</button>
      </form>
      {messageSent && (
        <div className="Sent-status">Message sent Successfully</div>
      )}
    </div>
  );
}

export default ContactForm;
