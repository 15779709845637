import React, { useState } from "react";
import { firestore } from "./Firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import "./App.css";

function ReviewForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    if ((value.length <= 1 && value.match(/^[A-Za-z]$/)) || value === "") {
      setLastName(value.toUpperCase());
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (rating > 0 && firstName && lastName) {
      try {
        await addDoc(collection(firestore, "Reform Ortho"), {
          "First Name": firstName,
          "Last Name": lastName,
          Rating: rating,
          "Review Text": reviewText,
          Date: Timestamp.fromDate(new Date()),
        });
        setFirstName("");
        setLastName("");
        setRating(0);
        setReviewText("");
      } catch (error) {
        console.error("Error adding review: ", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder="First Name"
      />
      <input
        type="text"
        value={lastName}
        onChange={handleLastNameChange}
        placeholder="Last Initial"
        maxLength="1"
      />
      <div className="rating">
        {[...Array(5)].map((_, index) => (
          <React.Fragment key={index}>
            <input
              type="radio"
              id={`star-${index + 1}`}
              name="rating"
              value={5 - index}
              checked={rating === 5 - index}
              onChange={() => setRating(5 - index)}
            />
            <label htmlFor={`star-${index + 1}`}>
              <span className="star">&#9733;</span>
            </label>
          </React.Fragment>
        ))}
      </div>

      <textarea
        value={reviewText}
        onChange={(e) => setReviewText(e.target.value)}
        placeholder="Review"
      />
      <button type="submit">Submit Review</button>
    </form>
  );
}

export default ReviewForm;
