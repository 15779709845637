import React, { useState } from 'react';
import ReviewList from './Reviewlist';
import ReviewForm from './Reviewform';
import './App.css';


function Testimonialsapp() {

  return (
    <div className="Reviews">
      <div className="Reviewform-Container">
        <ReviewForm />
      </div>
      <div className="Reviewlist-Container">
        <ReviewList />
      </div>
    </div>

  );
}

export default Testimonialsapp;