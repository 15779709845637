import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import AppNavbar from "./Navbar";
import Homeapp from "./Home";
import Aboutusapp from "./Aboutus";
import Providersapp from "./Providers";
import Servicesapp from "./Services";
import Testimonialsapp from "./Testimonials";
import Contactapp from "./Contact";

function App() {
  return (
    <Router>
      <div>
        <div className="Navbar-App">
          <AppNavbar />
        </div>
        <Routes>
          <Route exact path="/" element={<Homeapp />} />
          <Route path="/aboutus" element={<Aboutusapp />} />
          <Route path="/providers" element={<Providersapp />} />
          <Route path="/services" element={<Servicesapp />} />
          <Route path="/testimonials" element={<Testimonialsapp />} />
          <Route path="/contact" element={<Contactapp />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
