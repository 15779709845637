import React from "react";
import './App.css';


function Aboutusapp() {
    return (
        <div className="about-us">
            <header className="about-us-header">
                <div className="aboutus-text">
                    <p>
                        Reform Ortho & Sports Rehab is a diagnosis-driven clinic dedicated to customized treatment plans that resolve pain and other symptoms while improving your overall health and vitality. At the Dallas, Texas, office, experienced chiropractor and certification in physiotherapy, Travis Mann, DC, offers onsite diagnostics and treatments for neuromuscular and skeletal conditions resulting from traumatic injuries, sports participation, and degenerative disease.
                        Dr. Mann values a multidisciplinary approach to caring for his patients, providing access to a network of experienced family practitioners, pain specialists, and surgeons. This ensures every need of each patient is properly cared for and treated with the highest level of care.
                        Reform Ortho & Sports Rehab offers many onsite services for treating and rehabilitating injuries. In addition to personalized chiropractic care, Dr. Mann and his team provide massage therapy, spinal decompression services, and therapeutic exercise training. The team also focuses on optimizing overall health and patient education for preventing additional injuries down the road.
                        Learn more about available services for chronic pain, vertigo, herniated discs, and other painful or debilitating conditions by calling the office or requesting an appointment online today.
                    </p>
                </div>
            </header>
        </div>
    );
}

export default Aboutusapp;