import React, { useState } from "react";
import "./App.css";

const profilesData = [
  {
    name: "Dr. Travis Mann",
    imageSrc: "images/2004205.png",
    description: (
      <div className="Drmann-bio">
        <p>
          Meet Dr. Travis Mann, DC, a distinguished chiropractor and certified
          physiotherapist renowned for his expertise in diagnosing and treating
          a wide array of painful and debilitating conditions. At the heart of
          Farmers Branch, Texas, Dr. Mann leads the team at Reform Ortho &
          Sports Rehab with a steadfast commitment to excellence in
          patient-centered care. His unique blend of extensive knowledge,
          practical experience, and a deep passion for improving patients' lives
          positions him at the forefront of healthcare.
        </p>
        <p>
          Dr. Mann's esteemed career is marked by his significant tenure as the
          clinic director and chief of staff at a prominent physical medicine
          clinic. This pivotal role afforded him the invaluable opportunity to
          collaborate with a diverse team of specialists, ranging from
          orthopedic surgeons to neurologists. Through these collaborations, Dr.
          Mann gained profound insights into multifaceted treatment strategies,
          focusing on aggressive yet patient-tailored approaches to ensure swift
          and complete healing. Beyond clinical practice, he has been a driving
          force in technological advancements in healthcare, playing a key role
          in the development of two innovative electronic health record systems.
        </p>
        <p>
          Dr. Mann's academic journey is as impressive as his professional one.
          He embarked on his path to medical excellence at Nebraska Wesleyan
          University in Lincoln, where he acquired a Bachelor of Science in
          Exercise Science, laying the foundational stone for his future
          endeavors. His quest for knowledge led him to Parker College of
          Chiropractic in Dallas, where he earned his Doctorate in Chiropractic,
          a testament to his dedication and skill. Further honing his expertise,
          Dr. Mann achieved a certification in Chiropractic Clinical Neurology
          and delved deep into Neurovestibular Sciences, including specialized
          training in vestibular rehabilitation therapy. These accomplishments
          reflect not only his academic prowess but also his commitment to
          providing comprehensive care in his field.
        </p>
        <p>
          At the helm of Reform Ortho & Sports Rehab, Dr. Mann masterfully
          crafts personalized treatment plans, employing a holistic and
          multidisciplinary approach to confront and alleviate a myriad of
          conditions, including the complexities of chronic back pain, the
          disorienting challenges of vertigo, the persistent discomfort of
          osteoarthritis, and the debilitating effects of plantar fasciitis. His
          methodology is underpinned by the utilization of state-of-the-art
          diagnostic technologies, enabling him to delve into the intricacies of
          each patient's condition, uncovering the root causes of pain and
          symptoms. This precision-driven approach not only reflects Dr. Mann's
          commitment to advanced medical practice but also his unwavering
          dedication to restoring his patients' health and well-being.
        </p>
        <p>
          Away from the clinical environment, Dr. Mann embraces an active
          lifestyle, channeling his energy into the disciplined art of mixed
          martial arts, the serene focus of golf, and the tranquil pursuit of
          fishing. His zest for life extends into his personal realm, where he
          cherishes moments with his beloved wife, Lauren, and their three
          daughters. Together, they find joy in the simple pleasures of cooking,
          the refreshing tranquility of nature walks, and the soothing ambiance
          of beachside relaxation. This harmonious balance between his
          professional dedication and personal fulfillment exemplifies Dr.
          Mann's holistic approach to life.
        </p>
      </div>
    ),
  },
  {
    name: "Dr Jay P. Shah",
    imageSrc: "images/docjay.PNG", // Replace with the actual image file path
    description: (
      <div className="Drjay-bio">
        <p>
          Jay P. Shah, M.D., is an Assistant Professor in the Department of
          Orthopaedic Surgery at UT Southwestern Medical Center. He practices in
          the Sports Medicine Clinic at the UT Southwestern Clinical Center at
          Richardson/Plano, specializing in sports injuries, ACL reconstruction,
          and other complex issues of the knee and shoulder.
        </p>
        <p>
          Dr. Shah earned his medical degree at Rush Medical College in Chicago
          and completed a residency in orthopaedic surgery at the University of
          Alabama at Birmingham. He then received advanced training in sports
          medicine through a fellowship at Baylor College of Medicine.
        </p>
        <p>
          While in Birmingham and Houston, Dr. Shah trained under world-renowned
          sports surgeons and served as an assistant team physician for four of
          Houston’s major league teams – the Texans (NFL), Rockets (NBA), Astros
          (MLB), and Dynamo (MLS). Certified by the American Board of
          Orthopaedic Surgery, he joined the UT Southwestern faculty in 2017.
          Dr. Shah is an active member of numerous national organizations,
          including the American Academy of Orthopaedic Surgeons, the American
          Orthopaedic Society for Sports Medicine, the Arthroscopy Association
          of North America, the Mid-America Orthopaedic Association, and the
          Texas Orthopaedic Association.
        </p>
        <p>
          He has authored articles for Clinical Biomechanics, KSSTA,
          Orthopedics, American Journal of Sports Medicine, American Journal of
          Orthopedics, Arthroscopy, Clinical Anatomy, Spine, and Transactions of
          the Orthopaedic Research Society. He also serves as a writer and
          reviewer for orthobullets.com, a website that aids residents, fellows,
          and practicing surgeons with exam preparation.
        </p>
        <p>
          Dr. Shah is an avid sports enthusiast and can be seen on the sidelines
          at various local high school football teams. He enjoys spending time
          with his wife and two sons, traveling, and playing sports with his
          family.
        </p>
        <p>
          In 2019 and 2020, Dr. Shah was named a Texas Monthly Super Doctor
          Rising Star.
        </p>
      </div>
    ),
  },
  {
    name: "Nicole Taylor Family Nurse Practitioner",
    imageSrc: "images/441519.png",
    description: (
      <div className="Nicole-bio">
        <p>
          As a dedicated and compassionate medical professional, Nicole is
          committed to providing exceptional healthcare with a focus on
          patient-centered, evidence-based practices. With a diverse background
          and extensive experience in bioidentical hormone therapy, Nicole has
          emerged as a respected authority in the medical community.
        </p>
        <p>
          Education: Nicole earned her Bachelor's in Science of Nursing from
          Texas Women’s University in 2009 and her Master’s in Nursing at UT
          Arlington in 2017. During her training, she demonstrated a profound
          commitment to academic excellence, scoring in the top 5% on her
          clinical board certification examination.
        </p>
        <p>
          Clinical Expertise: As a board-certified family nurse practitioner,
          Nicole possesses a comprehensive understanding of how to optimize
          hormones, through different modalities of bioidentical hormone
          replacement therapy (BHRT). Her clinical expertise extends to treating
          hormone imbalances like polycystic ovarian syndrome (PCOS), assisting
          in weight loss, healing gut dysfunction, and utilizing peptide therapy
          for overall health optimization. Nicole continually stays abreast of
          the latest advancements in medical science to ensure that her patients
          receive the highest standard of care.
        </p>
        <p>
          Teaching and Mentorship: Committed to the education and mentorship of
          the next generation of healthcare professionals, Nicole is on the
          Evexipel training staff, teaching hundreds of other medical
          professionals on the skills and nuances of BHRT. She plays a pivotal
          role in shaping the future of healthcare by imparting her knowledge
          and skills to medical doctors, physician's assistants, and fellow
          nurse practitioners.
        </p>
        <p>
          Patient-Centered Approach: Known for her empathetic bedside manner and
          kind, calming demeanor, Nicole places a strong emphasis on building
          meaningful relationships with her patients. She believes in open
          communication, ensuring that patients fully understand their diagnoses
          and treatment options. Nicole is dedicated to providing personalized
          care that considers the unique needs and preferences of each
          individual.
        </p>
        <p>
          Personal: When not treating patients, Nicole is busy chasing after her
          4-year-old daughter Sophie, and 2.5-year-old son Maddox, along with
          her husband Terrill. She is just as passionate about her family as she
          is about her patients. She is an avid Peloton rider anytime she can
          get on and enjoys lifting weights to stay healthy. In her free time,
          Nicole enjoys watching football (go Cowboys), attending concerts, and
          traveling.
        </p>
        <p>
          With a strong dedication to excellence, a passion for advancing
          integrative medicine, and a genuine commitment to patient care, Nicole
          stands as a prominent figure in the field of BHRT. Her tireless
          efforts to improve healthcare outcomes, improve the quality of lives
          for her patients, and contribute to the betterment of the medical
          community make Nicole a highly respected and sought-after medical
          professional.
        </p>
        <p>
          “There is very little in the world that makes me happier than when a
          patient reports feeling like they are a new person, that they feel
          better than they have in years! I believe we are all born with unique
          gifts. My gift is to help people be healthier and feel great, in order
          to share their gifts with the world. I truly look forward coming to
          work!”
        </p>
      </div>
    ),
  },
];

function Profile({ name, imageSrc, description }) {
  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <div className="profile">
      <div className="name-button">
      {}
      <button onClick={toggleDescription} >
        {name}
      </button>
      </div>
      <button onClick={toggleDescription} className="profile-button">
        <div className="Biopictures">
          <img src={imageSrc} alt={name} className={`image-${imageSrc}`} />
        </div>
      </button>
      {}
      
      {showDescription && <div className="description">{description}</div>}
    </div>
  );
}

function Providersapp() {
  return (
    <div className="ProvidersApp">
      <h1>Meet Our Providers</h1>
      <div className="profiles">
        {profilesData.map((profile, index) => (
          <Profile
            key={index}
            name={profile.name}
            imageSrc={profile.imageSrc}
            description={profile.description}
          />
        ))}
      </div>
    </div>
  );
}

export default Providersapp;
