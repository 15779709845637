import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import "./App.css";
import CallButton from "./Callbutton";

function AppNavbar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth >= 1353);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= 1353);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Navbar collapseOnSelect expand="lg" className="Navbar" variant="dark">
      <Navbar.Brand>
        <Link to="/">
          <img
            src="images/2300223.png"
            width="300"
            height="100"
            className="d-inline-block align-top"
            alt="My Website Logo"
          />
        </Link>
        <div className="card">
          <div className="instabutton">
            <Link
              to="https://www.instagram.com/reformorthosport/"
              className="socialContainer containerOne"
            >
              <div className="logo-containerinsta">
                <img src="images/instagramlogo.png" alt="Instagram" />
              </div>
            </Link>
          </div>
          <div className="facebookbutton">
            <Link
              to="https://www.facebook.com/ReformOrthoSport"
              className="socialContainer containerTwo"
            >
              <div className="logo-containerface">
                <img src="images/tfacebook.png" alt="Facebook" />
              </div>
            </Link>
          </div>
          <div className="yelpbutton">
            <Link
              to="https://www.yelp.com/biz/reform-ortho-and-sports-rehab-dallas"
              className="socialContainer containerThree"
            >
              <div className="logo-containerface">
                <img src="images/yelplogo.png" alt="Facebook" />
              </div>
            </Link>
          </div>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        {isMobile ? (
          <Nav className="ml-auto">
            <NavLink>
              <CallButton />
            </NavLink>
            <NavLink to="/" activeclassname="active" className="nav-link">
              Home
            </NavLink>
            <NavLink
              to="/Providers"
              activeclassname="active"
              className="nav-link"
            >
              Providers
            </NavLink>
            <NavLink
              to="/Services"
              activeclassname="active"
              className="nav-link"
            >
              Services
            </NavLink>
            <NavLink
              to="/Testimonials"
              activeclassname="active"
              className="nav-link"
            >
              Testimonials
            </NavLink>
            <NavLink
              to="/Contact"
              activeclassname="active"
              className="nav-link"
            >
              Contact
            </NavLink>
          </Nav>
        ) : (
          <Nav className="ml-auto">
            <NavLink>
              <CallButton />
            </NavLink>
            <NavDropdown title="Menu" id="responsive-navbar-nav-dropdown">
              <NavLink
                to="/"
                activeclassname="active"
                className="dropdown-item"
              >
                Home
              </NavLink>
              <NavLink
                to="/Providers"
                activeclassname="active"
                className="dropdown-item"
              >
                Providers
              </NavLink>
              <NavLink
                to="/Services"
                activeclassname="active"
                className="dropdown-item"
              >
                Services
              </NavLink>
              <NavLink
                to="/Testimonials"
                activeclassname="active"
                className="dropdown-item"
              >
                Testimonials
              </NavLink>
              <NavLink
                to="/Contact"
                activeclassname="active"
                className="dropdown-item"
              >
                Contact
              </NavLink>
            </NavDropdown>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default AppNavbar;
