import React, { useState, useRef } from "react";
import "./App.css";

function Squaregrid() {
  const [selectedSquare, setSelectedSquare] = useState(null);
  const infoBoxRef = useRef(null);

  const backgrounds = [
    "url('/images/2004190.jpg')",
    "url('/images/2004194.jpg')",
    "url('/images/2004195.jpg')",
    "url('/images/2004196.jpg')",
    "url('/images/2004197.jpg')",
    "url('/images/2004198.jpg')",
    "url('/images/2004199.jpg')",
    "url('/images/2004200.jpg')",
    "url('/images/351238.jpg')",
    "url('/images/189205.png')",
    "url('/images/189202.png')",
    "url('/images/189203.png')",
    "url('/images/190212.png')",
  ];

  const gridTexts = [
    "Back Pain",
    "Osteoarthritis",
    "Carpal Tunnel Syndrome",
    "Chiropractor",
    "Plantar Fasciitis",
    "Shoulder Pain",
    "Herniated Disc",
    "Vertigo",
    "Shockwave Therapy",
    "Joint Rejuvenation",
    "Platelet-Rich Plasma (PRP)",
    "IV Therapy",
    "Hormone Therapy",
  ];

  const informationPopup = [
    <div className="Backpain-info">
      <p>
        Back pain is one of the leading causes of disability in the United
        States. If you miss work and other activities due to chronic back pain,
        experienced chiropractor with a certification in physiotherapy Travis
        Mann, DC, offers comprehensive care to restore your back and spine
        health. At Reform Ortho & Sports Rehab in Dallas, Texas, Dr. Mann
        provides convenient access to chiropractic services with a certification
        in physiotherapy, and massage therapy to get you back to your usual
        activities. Find out more about your options for chronic back pain by
        calling the office or booking a visit online today.
      </p>
      <h1>Back Pain Q & A</h1>
      <h2>What causes back pain?</h2>
      <p>
        Back pain is the result of many factors. When pain lasts more than six
        months, your pain is considered chronic. Chronic pain is often due to
        conditions like:
      </p>
      <p>Arthritis</p>
      <p>Muscle strains</p>
      <p>Herniated discs</p>
      <p>Spinal curvature</p>
      <p>Nerve compression</p>
      <p>
        You can also develop chronic back pain because of being overweight or
        improperly lifting heavy objects. As you get older, your spinal
        vertebrae begin to deteriorate, which can lead to chronic back pain and
        a gradual loss of your mobility.
      </p>
      <h2>How does chiropractic care help with back pain?</h2>
      <p>
        As an experienced chiropractor, Dr. Mann knows many techniques that
        address the root cause of your back pain. If you have nerve compression
        issues from an injury or herniated disc, Dr. Mann may recommend spinal
        decompression therapy. This type of treatment involves spinal stretching
        maneuvers on a special table to address vertebrae misalignments or
        damaged discs that press on your nerves. Dr. Mann also performs manual
        and instrument-assisted manipulations to correct the alignment of your
        spine and improve your mobility. Over the course of several
        appointments, Dr. Mann makes necessary adjustments to realign the joints
        of your spine, hips, and shoulders to alleviate chronic pain. Dr. Mann
        creates a comprehensive treatment plan based on your specific needs with
        the goal of alleviating pain for the long-term and reducing the
        worsening of your pain and mobility issues.
      </p>
      <h2>What other treatments are available for chronic back pain?</h2>
      <p>
        In addition to chiropractic care, Dr. Mann evaluates the severity of
        your condition and recommends other therapies, including massage and
        services with a certification in physiotherapy. Massage therapy is good
        for relaxing the back muscles that support your spine and increasing
        blood flow to injured muscles, ligaments, and tendons. With his
        certification in physiotherapy, Dr. Mann can review its benefits
        including boosting the muscles that support your spine and restoring
        your mobility and the range of motion in your back. Dr. Mann may also
        recommend at-home exercises to keep your spine strong and prevent
        additional injuries that worsen existing back pain. If chronic back pain
        reduces your quality of life, don’t delay an evaluation. Schedule a
        visit with Dr. Mann today by calling the office or requesting an
        appointment online.
      </p>
    </div>,

    <div className="Osteoarthritis-info">
      <p>
        As you get older and your joints begin to wear down, chronic pain may
        become an everyday factor in your life. At Reform Ortho & Sports Rehab,
        experienced chiropractor with a certification in physiotherapy Travis
        Mann, DC, offers comprehensive chiropractic care to alleviate pain and
        increase your mobility. Dr. Mann and his team also provide massage and
        service with certification in physiotherapy onsite at their Dallas,
        Texas, office. If you’re looking for noninvasive solutions for managing
        chronic osteoarthritis pain, schedule a consultation online or call the
        office now.
      </p>
      <h1>Osteoarthritis Q & A</h1>
      <h2>What is osteoarthritis?</h2>
      <p>
        Osteoarthritis is a chronic joint disease that causes progressive
        degeneration of the bone, cartilage, and ligaments. As the cartilage
        that cushions your joints wears away, it allows your bones to rub
        together, producing chronic pain and changes in the shape of your
        joints. Osteoarthritis is common in elderly people but can also develop
        at an early age due to previous joint injury. While it affects any joint
        in your body, you are more susceptible to developing osteoarthritis in
        your:
      </p>
      <p>Hands</p>
      <p>Hips</p>
      <p>Knees</p>
      <p>Spine</p>
      <p>
        The condition develops gradually and over time, and your symptoms may
        become severe enough to reduce your mobility and range of motion. The
        hallmark symptoms of osteoarthritis are chronic joint pain and
        stiffness. You may also notice that your joints crack or make a grinding
        noise with your movements. Without proper treatment, osteoarthritis
        leads to permanent deformities in your joints.
      </p>
      <h2>What are the risk factors for osteoarthritis?</h2>
      <p>
        In addition to the natural aging process and a previous injury to your
        joints, your risk for osteoarthritis increases due to factors like:
      </p>
      <p>Obesity</p>
      <p>Family history</p>
      <p>Repetitive use injuries</p>
      <p>
        While there’s no cure for osteoarthritis, Dr. Mann creates treatment
        plans to reduce chronic pain related to the condition and increase your
        overall function. His plan for you also focuses on slowing down the
        progression of the disease.
      </p>
      <h2>What treatments are available for osteoarthritis?</h2>
      <p>
        Initial treatment for easing osteoarthritis pain may involve making
        lifestyle changes to lose weight and reduce pressure on the affected
        joint. Dr. Mann also discusses therapeutic exercises to strengthen the
        muscles that support your damaged joint. Chiropractic care is effective
        at relieving chronic joint pain. Dr. Mann uses several techniques to
        manipulate the affected joint to improve blood circulation and reduce
        pain. He may also recommend massage therapy to reduce muscle tension
        that increases osteoarthritis-related pain. In many cases, active and
        passive therarpy are important for increasing your range of motion and
        reducing your risk for disability due to osteoarthritis. During therapy,
        you’ll also learn at-home techniques to keep your body strong and
        flexible. Find out more about customized treatment plans for addressing
        osteoarthritis pain by calling Reform Ortho & Sports Rehab today or
        requesting an appointment online now.
      </p>
    </div>,
    <div className="Carpaltunnel-info">
      <p>
        If you spend a lot of time typing on a computer or suffer an injury to
        your wrist, you may struggle with chronic pain and other symptoms of
        carpal tunnel syndrome. As an experienced chiropractor with a
        certification in physiotherapy, Travis Mann, DC, offers comprehensive
        chiropractic care for carpal tunnel pain at Reform Ortho & Sports Rehab
        in Dallas, Texas. Dr. Mann and his team use manual manipulations, and
        service with certification in physiotherapy, and other methods to
        restore the function of your hand and wrist. Learn more about
        noninvasive treatments for carpal tunnel syndrome by calling the office
        or requesting a consultation online today
      </p>
      <h1>Carpal Tunnel Syndrome Q & A</h1>
      <h2>What is carpal tunnel syndrome?</h2>
      <p>
        Carpal tunnel syndrome is a condition that develops when the median
        nerve, the major nerve in your hand, is compressed due to chronic
        irritation and inflammation. The condition is also the result of a
        narrowing of your carpal tunnel, the passage in the wrist that houses
        the median nerve. You are at increased risk for developing carpal tunnel
        syndrome due to a direct injury to your wrist or because of changes that
        occur as you age. Many people develop chronic symptoms of carpal tunnel
        syndrome due to the requirements of their job, such as computer use or
        using power tools that vibrate, which can put additional pressure on the
        median nerve. Underlying medical issues, like rheumatoid arthritis, a
        thyroid disorder, and diabetes, also increase your risk for carpal
        tunnel syndrome.
      </p>
      <h2>What are the symptoms of carpal tunnel syndrome?</h2>
      <p>
        The most common symptom of carpal tunnel syndrome is numbness and pain
        in the fingers and hand. You may also feel shock-like sensations that
        radiate up your arm. Other common symptoms of carpal tunnel syndrome
        include:
      </p>
      <p>Hand weakness</p>
      <p>Difficulties grasping objects</p>
      <p>Frequently dropping things</p>
      <p>
        Symptoms of carpal tunnel syndrome typically develop over time, coming
        and going without warning. However, continued pressure on the median
        nerve can result in chronic pain and difficulties using your hand,
        fingers, and wrist.
      </p>
      <h2>What treatments are available for carpal tunnel syndrome?</h2>
      <p>
        To reduce pain and other issues associated with carpal tunnel syndrome,
        Dr. Mann offers comprehensive chiropractic care. He focuses his
        treatments on alleviating pressure on the median nerve and uses gentle
        manual manipulations in your elbow, wrist, and cervical spine to address
        misalignments. With his certification in physiotherapy, Dr. Mann may
        perscribe active and passive therearpy to promote the recovery and
        health of the muscles that support your wrist and hand. In some cases,
        you may need bracing to give your wrist a chance to heal before starting
        strengthening exercises. If you struggle to grasp objects or if the
        chronic pain of carpal tunnel syndrome interferes with your work or
        personal life, schedule a consultation with Dr. Mann. You can schedule a
        visit online or by calling Reform Ortho & Sports Rehab today.
      </p>
    </div>,
    <div className="Chiropractor-info">
      <p>
        When chronic pain from arthritis or a herniated disc slows you down, you
        can find relief without surgery with the help of an experienced
        chiropractor. At Reform Ortho & Sports Rehab in Dallas, Texas, Travis
        Mann, DC, is skilled in diagnosing the root cause of your pain. He
        specializes in advanced chiropractic treatments, like manual
        manipulations and spinal decompression therapy, to alleviate pain and
        boost your overall health and vitality. Learn more about the benefits of
        working with a chiropractor by calling the office or using the online
        booking system to request an appointment.
      </p>
      <h1>Chiropractor Q & A</h1>
      <h2>Why should I consider seeing a chiropractor?</h2>
      <p>
        Chiropractic care is a beneficial service for many muscle and nervous
        system disorders. Dr. Mann is a highly experienced chiropractor who
        focuses on the right treatment plan for you to alleviate pain and other
        symptoms. His chiropractic services can also boost your general health,
        strengthen your immune system, and revitalize your overall function. You
        can benefit from chiropractic services if you suffer from pain or other
        symptoms that interfere with your quality of life, such as:
      </p>
      <p>Vertigo</p>
      <p>Arthritis</p>
      <p>Herniated disc</p>
      <p>Repetitive use injuries</p>
      <p>Chronic pain in the shoulder, back, and neck</p>
      <p>
        Chiropractic care is also a great way to bring balance to your body and
        ensure your spine and other joints are in proper alignment. This balance
        and alignment are crucial for ensuring all of your major systems
        function properly.
      </p>
      <h2>What chiropractor services are available?</h2>
      <p>
        As an experienced chiropractor, Dr. Mann specializes in a variety of
        techniques to realign your spine and address chronic pain and other
        symptoms of a muscle or nerve injury. He uses a combination of manual
        manipulations, instrument-assisted manipulations, and therapeutic
        exercises to alleviate pain and improve the alignment of your body.
        Additionally, you can receive spine decompression therapy to address
        chronic back pain resulting from arthritis, nerve compression, or
        herniated discs. Spinal decompression therapy involves stretching your
        spine on a special treatment table to alleviate compression on the
        spinal nerves. Dr. Mann even recommends massage therapy to enhance your
        blood circulation and stimulate your body’s own natural healing
        processes.
      </p>
      <h2>What can I expect during my first chiropractor visit?</h2>
      <p>
        During your initial consultation with Dr. Mann, he reviews your medical
        history and listens to your symptoms. He performs a physical examination
        of the affected area, checking your range of motion, flexibility, and
        the severity of your discomfort or pain. In many cases, Dr. Mann begins
        treatment on your first visit, using manual and instrument-assisted
        therapies to address misalignments in the joints and spine. He also
        creates a treatment schedule for you to receive additional chiropractic
        treatments to achieve optimum health. It can take several sessions for
        you to feel significant pain relief. Dr. Mann works closely with you,
        monitoring your progress through each appointment. If you’re looking for
        noninvasive solutions for chronic pain or mobility issues, schedule a
        consultation at Reform Ortho & Sports Rehab by phone or use the online
        booking feature today.
      </p>
    </div>,
    <div className="Plantarfasciitis-info">
      <p>
        Chronic heel pain is the first indication you have plantar fasciitis. At
        Reform Ortho & Sports Rehab in Dallas, Texas, you can receive an onsite
        evaluation of your heel pain to confirm plantar fasciitis from
        experienced chiropractor with a certification in physiotherapy Travis
        Mann, DC. Dr. Mann specializes in custom treatment plans for chronic
        heel pain and even offers referrals for surgery. Find out more about
        available treatments for plantar fasciitis by calling the office or by
        requesting an appointment online today.
      </p>
      <h1>Plantar Fasciitis Q & A</h1>
      <h2>What is plantar fasciitis?</h2>
      <p>
        Plantar fasciitis is a condition that develops when the thick band of
        tissue that extends across the bottom of your foot becomes inflamed.
        This tissue, known as the plantar fascia, connects your toes to your
        heel bone. The plantar fascia absorbs the shock of your movements. When
        you put too much stress or pressure on the tissue, it develops tears
        that cause chronic tissue irritation and inflammation. While anyone can
        develop plantar fasciitis, your risk for the condition increases due to
        factors like:
      </p>
      <p>Aging</p>
      <p>Obesity</p>
      <p>Being flat-footed</p>
      <p>High-impact exercise</p>
      <p>
        You are also at increased risk for plantar fasciitis if you spend long
        hours on your feet due to your job or hobbies. Athletes are prone to
        plantar fasciitis because of the repetitive movements required of many
        sports.
      </p>
      <h2>How is plantar fasciitis diagnosed?</h2>
      <p>
        The hallmark symptom of plantar fasciitis is a stabbing pain that
        develops near the heel in the bottom of your foot. Dr. Mann typically
        confirms a plantar fasciitis diagnosis based on your symptoms. You can
        expect a physical examination of your foot, and Dr. Mann may ask you to
        walk or perform other movements to evaluate the location of your pain.
        He also assesses how pain affects your ability to stay active. Based on
        the severity of your pain and how plantar fasciitis affects the quality
        of your life, Dr. Mann creates a treatment plan to alleviate pain and
        prevent a worsening of the injury.
      </p>
      <h2>What treatments are available for plantar fasciitis?</h2>
      <p>
        Initially, Dr. Mann may recommend over-the-counter anti-inflammatories
        and pain relievers to keep you comfortable. He may perform manual
        stretching exercises on your foot to strengthen the surrounding muscles
        and alleviate pressure on the ligaments. Treatment plans for plantar
        fasciitis typically involve a combination of active or passive therapy
        and therapeutic exercises. Dr. Mann may also suggest orthotic devices
        that you wear in your shoes to take excess pressure off your plantar
        fascia. In some cases, severe pain that interferes with walking and your
        usual routine needs treatment with steroid injections or surgery. Dr.
        Mann evaluates your needs and refers you to a specialist for additional
        treatment if needed. If stabbing heel pain affects your quality of life,
        don’t delay treatment. You can schedule a consultation at Reform Ortho &
        Sports Rehab today using the online looking feature or by calling the
        office.
      </p>
    </div>,
    <div className="Shoulder-info">
      <p>
        While occasional shoulder pain affects many people, chronic pain can
        interfere with your ability to stay active. To address chronic shoulder
        pain without surgery, experienced chiropractor with a certification in
        physiotherapy Travis Mann, DC, offers a variety of treatments at Reform
        Ortho & Sports Rehab in Dallas, Texas. Dr. Mann and his team create a
        treatment plan to alleviate pain and restore your shoulder’s function
        using chiropractic care, service with certification in physiotherapy,
        and other techniques. Find out how chiropractic care can relieve
        shoulder pain by calling the office or requesting an appointment online
        today.
      </p>
      <h1>Shoulder Pain Q & A</h1>
      <h2>What causes shoulder pain?</h2>
      <p>
        Your shoulder is made up of several joints, muscles, and tendons that
        give you a wide range of motion. When any of these structures are
        damaged by injury or disease, ou can develop chronic shoulder pain and
        loss of movement. Some of the common causes of shoulder pain include the
        following conditions:
      </p>
      <p>Arthritis</p>
      <p>Inflammation</p>
      <p>Tendon tears</p>
      <p>Joint instability</p>
      <p>Degenerative joint disease</p>
      <p>
        You may also develop impingement issues, which occur when pressure is
        put on your soft tissues each time you lift your arm away from your
        body. Lifting causes the shoulder blade to push on your rotator cuff
        tendons, leading to chronic pain and limited movement.
      </p>
      <h2>What are my risk factors for shoulder pain?</h2>
      <p>
        There are a number of factors that influence the health of your
        shoulder. As you get older, your joints begin wearing down, leading to
        chronic pain. You may also be prone to developing arthritis due to age
        or previous shoulder injuries. In addition to aging, your risk for
        chronic shoulder pain may be due to repetitive motions involved in
        sports participation, your job, or other activities.
      </p>
      <h2>What treatments are available for shoulder pain?</h2>
      <p>
        Depending on the root cause of your shoulder pain, Dr. Mann may
        recommend chiropractic adjustments to your cervical spine and shoulder
        to relieve pain and increase your mobility. Massage therapy also
        alleviates pain and increases the circulation of blood into the shoulder
        joint to promote natural healing. This type of therapy is also good for
        relaxing tense muscles in the shoulder and neck. With his certification
        in physiotherapy, Dr. Mann may perscribe active or passive therarpy and
        therapeutic exercises you can do at home to strengthen the muscles in
        your shoulder that support the joint. Exercises are also effective in
        addressing chronic pain and increasing your range of motion. In many
        cases, adjusting your lifestyle to reduce repetitive motions also
        improves your shoulder health. You may need to request light-duty work
        or take time off from sports that can aggravate an existing shoulder
        condition. Find out more about nonsurgical treatments available for
        shoulder pain by calling Reform Ortho & Sports Rehab or requesting an
        appointment online today.
      </p>
    </div>,
    <div className="Herniateddisc-info">
      <p>
        If you experience electric shocks of pain in your back that worsen with
        movement, you may have a herniated disc. Experienced chiropractor with a
        certification in physiotherapy Travis Mann, DC, understands the
        frustration chronic pain can cause. At Reform Ortho & Sports Rehab in
        Dallas, Texas, Dr. Mann and his team offer comprehensive care for
        herniated disc pain, including onsite spinal decompression therapy and
        other chiropractic techniques. Learn more about noninvasive treatments
        available for herniated disc pain by calling the office or requesting an
        appointment online now.
      </p>
      <h1>Herniated Disc Q & A</h1>
      <h2>What is a herniated disc?</h2>
      <p>
        Protecting your spinal vertebrae is the primary job of the rubber discs
        that sit between the bones. These durable discs have a tough outer layer
        and a soft center, absorbing the shock of your movements and preventing
        your vertebrae from rubbing together. As you get older or due to an
        injury in your spine, your spinal discs can break down and force the
        soft center of the disc outward through the outer layer. The gel-like
        material can also put pressure on surrounding spinal nerves, causing
        chronic pain and reducing your range of motion. While you can develop a
        herniated disc in any area of your spine, damaged discs are more common
        in your lumbar spine (lower back) and your cervical spine (neck).
      </p>
      <h2>What are the symptoms of a herniated disc?</h2>
      <p>
        The symptoms of a herniated disc vary depending on where the damaged
        disc sits and how much pressure is placed on your nerves. For some with
        a herniated disc, no symptoms are noticeable. However, many with a
        herniated disc experience pain that becomes severe enough to be
        disabling. Other symptoms of a herniated disc include:
      </p>
      <p>Burning</p>
      <p>Tingling</p>
      <p>Numbness</p>
      <p>Shock-like jolts of pain</p>
      <p>You may also have pain that radiates into your arms or legs.</p>
      <h2>What treatments are available for a herniated disc?</h2>
      <p>
        In many cases, a herniated disc is treatable with nonsurgical treatments
        such as chiropractic care and services with certification in
        physiotherapy. Dr. Mann creates a treatment plan that focuses on
        relieving pain and improving your overall function. In addition to
        manual and instrument-assisted manipulations, Dr. Mann offers in-office
        spinal decompression therapy. This type of treatment involves stretching
        the spine on a special table to reduce the pressure a damaged disc puts
        on your nerves. Additionally, Dr. Mann may recommend over-the-counter
        pain relievers and anti-inflammatories to keep you pain-free. Massage
        therapy is also available onsite to ncrease blood circulation and
        alleviate tension in your muscles that worsens back pain. Find out more
        about nonsurgical treatments available for herniated disc pain by
        calling the Reform Ortho & Sports Rehab office or by requesting an
        appointment through the online booking system.
      </p>
    </div>,
    <div className="Vertigo-info">
      <p>
        If you often feel like the room is spinning around you or constant
        dizziness interferes with your health, you may have vertigo. At Reform
        Ortho & Sports Rehab in Dallas, Texas, experienced chiropractor Travis
        Mann, DC, is skilled in treating vertigo with a variety of noninvasive
        chiropractic techniques. He assesses the cause of your vertigo symptoms
        and creates a treatment plan to alleviate the severity and frequency of
        your dizziness. Schedule an evaluation for vertigo today by calling the
        office or booking a consultation online now.
      </p>
      <h1>Vertigo Q & A</h1>
      <h2>What is vertigo?</h2>
      <p>
        Vertigo is a condition that causes you to feel lightheaded or dizzy.
        People with vertigo also experience sensations like the room they’re in
        is spinning even when the person is standing or lying still. The
        condition is often the result of problems in your inner ear, but vertigo
        can also indicate a more serious problem in your brain or the pathways
        of your sensory nerves. In many cases, misalignments in the spine are a
        common cause of frequent vertigo. You can also develop vertigo as a side
        effect of other conditions, including:
      </p>
      <p>Anxiety</p>
      <p>Headaches</p>
      <p>Motion sickness</p>
      <p>Poor circulation</p>
      <p>Balance problems</p>
      <p>Low blood pressure</p>
      <p>Nausea and vomiting</p>
      <p>
        It’s important to seek an evaluation with Dr. Mann to determine the root
        cause of your vertigo symptoms and rule out more serious health issues.
      </p>
      <h2>How is vertigo diagnosed?</h2>
      <p>
        If you experience dizziness due to a head injury, seek immediate medical
        treatment at the local ER to prevent a worsening of your symptoms or
        other health complications. If vertigo symptoms develop over time for no
        apparent reason, Dr. Mann performs in-office tests, evaluating the
        movement of your head and eyes and your balance. Based on the severity
        of your symptoms, Dr. Mann creates a treatment plan to reduce the
        frequency of vertigo episodes.
      </p>
      <h2>What treatments are available for vertigo?</h2>
      <p>
        Chiropractic care is a highly effective treatment for vertigo,
        especially if your condition originates from misalignments in your spine
        or inner ear problems. Dr. Mann determines which chiropractic techniques
        are most beneficial based on the root cause of your condition. He
        typically uses manual or instrument-assisted manipulations to restore
        alignment in your spinal joints, especially in the cervical spine, or
        neck. For ear-related issues, Dr. Mann performs the Epley Maneuver,
        which helps to reposition the bony particles of your inner ear to
        restore balance. He may also discuss therapeutic exercises you can do to
        address vertigo caused by inner ear issues. If your quality of life is
        affected by frequent vertigo symptoms, learn how chiropractic care can
        help by scheduling a consultation at Reform Ortho & Sports Rehab online
        or by calling the office today.
      </p>
    </div>,
    <div className="Shockwavetherapy-info">
      <p>
        Up to 80% of patients say that shockwave therapy has helped them resolve
        their problem. If you struggle with ongoing pain, a simple, noninvasive
        solution is available at Reform Ortho & Sports Rehab. Experienced
        chiropractor Travis Mann, DC, offers shockwave therapy in Dallas, Texas,
        to restore musculoskeletal functioning without medication or surgery.
        Call his office to schedule an appointment or book one online today.
      </p>
      <h1>Shockwave Therapy Q & A</h1>
      <h2>What is shockwave therapy?</h2>
      <p>
        Shockwave therapy uses painless acoustic energy to stimulate cell
        regeneration and your body’s natural healing capabilities. It carries
        high-energy waves to painful or damaged tissues to promote cell
        regeneration and repair tendons, muscles, other soft tissues, or bones.
        This type of treatment is highly effective for restoring your mobility
        and relieving discomfort without pain, surgery, harsh medicines, or
        downtime.
      </p>
      <h2>What are the benefits of shockwave therapy?</h2>
      <p>
        Some of the many benefits you can expect when choosing shockwave therapy
        at Reform Ortho & Sports Rehab include:
      </p>
      <p>More collagen production</p>
      <p>Cell regeneration </p>
      <p>New blood vessel formation</p>
      <p>Reduced inflammation</p>
      <p>Less pain</p>
      <p>Faster healing</p>
      <p>Reduced tension</p>
      <p>Trigger point release</p>
      <p>Natural tissue repair</p>
      <p>Easier mobility</p>
      <p>No downtime</p>
      <p>
        Shockwave therapy offers dramatic reductions in pain, thereby improving
        your mobility and overall quality of life.
      </p>
      <h2>Why might I need shockwave therapy?</h2>
      <p>Dr. Mann might recommend shockwave therapy if you struggle with:</p>
      <p>Shoulder, knee, or hip pain</p>
      <p>Other types of joint pain</p>
      <p>Jumper’s knee</p>
      <p>Shoulder injuries</p>
      <p>Tennis elbow</p>
      <p>Heel spurs</p>
      <p>Ongoing or severe pain</p>
      <p>Calcifications</p>
      <p>Tendon problems</p>
      <p>Strains</p>
      <p>Sprains</p>
      <p>Fractures</p>
      <p>Other injuries</p>
      <p>
        He shares his professional opinion after reviewing your symptoms,
        discussing your medical history with you, and completing a physical
        exam. He could order tests, X-rays, MRIs, CT scans, or other imaging
        procedures to diagnose your condition and treat it properly.
      </p>
      <h2>What should I expect during shockwave therapy?</h2>
      <p>
        During shockwave therapy at Reform Ortho & Sports Rehab, you rest on a
        comfortable exam table or chair. Your specialist uses a hand-held device
        to apply acoustic energy to targeted tissues. You may feel discomfort
        during treatment, but it lasts just five minutes. Most patients need a
        series of treatments to achieve the best outcome, but you can expect
        pain relief after your first session. You might have some soreness at
        the treatment area within 2-4 hours, but it doesn't last long. Avoid
        strenuous activity for about 48 hours, or as directed by Dr. Mann,
        following each shockwave therapy session. He lets you know when to
        schedule your next treatment to maximize your results. To learn more
        about shockwave therapy at Reform Ortho & Sports Rehab and find out if
        it’s right for you, call the office or use the online booking tool
        today.
      </p>
    </div>,
    <div className="Joint-rejuvenation-info">
      <p>
        Your body is filled with cells and substances aimed at healing and
        repairing diseased and damaged tissue. However, aging and poor blood
        supply may affect the delivery of your body’s natural healing elements
        to the tissues and organs that need it. Our experienced medical provider
        and orthopedic surgeon, Dr. Michael Duchamp, and Reform Regenerative
        Health team in Farmers Branch, Texas, offers Wharton’s Jelly, connective
        tissue therapies that contain joint rejuvenation to provide your body
        with the boost it needs for structural support, cushioning, and cosmetic
        aesthetics. Call the office to request an appointment and learn more
        about joint rejuvenation treatment.
      </p>
      <h1>Joint Rejuvenation Q & A</h1>
      <h2>What is joint rejuvenation?</h2>
      <p>
        Regenerative cells are the undifferentiated cells (blank cells) in your
        body that have the ability to transform into differentiated cells such
        as muscle, brain, bone, or blood vessel cells. Your body uses joint
        rejuvenation to regenerate needed cells, tissue, and organs to repair
        old or damaged cells. Though joint rejuvenation is part of your body’s
        repair system, these types of cells have limitations and may not be able
        to repair all the damaged cells or tissues in your body. Researchers
        continue to investigate the potential benefit joint rejuvenation may
        have for the future of medical care.
      </p>
      <h2>What is joint rejuvenation therapy?</h2>
      <p>
        Regenerative therapy is a form of regenerative medicine. Supplying your
        damaged tissue with the connective tissue containing joint rejuvenation
        needed to boost the repair process providing structural support and
        cushioning to damaged tissue and help you get back to your activities
        faster.
      </p>
      <h2>Am I a good candidate for this therapy?</h2>
      <p>
        When you come in to see our medical supervisor and Reform Regenerative
        Health team, they conduct a comprehensive evaluation to determine if
        you’re a good candidate for therapy. During your exam, they ask detailed
        questions about your health concerns, symptoms, medical history, and
        current and past treatments and therapies. They then conduct a physical
        exam and may request diagnostic testing to fully understand your health
        needs. Our experienced medical supervisor and Reform Regenerative Health
        team specialize in promoting structural support and cushioning and
        improving neuromuscular and skeletal conditions caused by injuries or
        degenerative diseases. You may be a good candidate for joint
        rejuvenation therapy if your injury or degenerative condition isn’t
        improving with traditional treatments. Our experienced medical
        supervisor and Reform Regenerative Health team also offer connective
        tissue containing joint rejuvenation therapy for cosmetic treatments
        including skin rejuvenation and hair restoration.
      </p>
      <h2>What can I expect with this therapy?</h2>
      <p>
        Our experienced medical supervisor and Reform Regenerative Health team
        review the details of your therapy at your initial consultation. In
        general, they inject the connective tissue containing joint rejuvenation
        directly into the damaged tissue. After your therapy, you are then sent
        home. Most patients resume their usual activities after their treatment.
        However, activity modification may be needed based on your specific
        diagnosis. This therapy containing joint rejuvenation isn’t a quick fix,
        and it may take several weeks until you start to notice an improvement
        in function. The Reform Regenerative Health team schedules regular
        follow-up appointments to monitor your progress and includes other
        treatments to support health and joint rejuvenation provides structural
        support and cushioning, such as chiropractic care. To learn more about
        connective tissue containing joint rejuvenation therapy and how it may
        benefit you and your health, call the office for more information and
        book your appointment today.
      </p>
    </div>,

    <div className="PlateletRich-Plasma-info">
      <p>
        Your body is filled with cells and substances aimed at repairing
        diseased and damaged tissue. However, aging and poor blood supply may
        affect the delivery of your body’s natural healing elements to the
        tissues and organs that need it. Our experienced medical provider and
        orthopedic surgeon, Dr. Michael Duchamp and Reform Regenerative Health
        team in Farmers Branch, Texas, offers Wharton’s Jelly, connective tissue
        therapies that contains regenerative cells to provide your body with the
        boost it needs for structural support, cushioning, and cosmetic
        aesthetics. Call the office to request an appointment and learn more
        about regenerative treatment.
      </p>
      <h1>Platelet-Rich Plasma (PRP) Q & A</h1>
      <h2>What is platelet-rich plasma (PRP)?</h2>
      <p>
        PRP is a type of regenerative medicine. This new and exciting area of
        medicine focuses on providing treatments and therapies that support your
        body’s natural healing process, rather than medication and treatments
        aimed at simply alleviating symptoms. Our experienced medical supervisor
        and Reform Regenerative Health team are dedicated to helping you get
        back to your active lifestyle as quickly as possible.
      </p>
      <h2>PRP</h2>
      <p>
        PRP is a blood product made from a sample of your own blood that
        contains a high concentration of platelets, growth factors, and
        specialized cells. When injected into diseased or damaged tissue, the
        beneficial properties in PRP provide your tissue with the extra support
        needed to repair the damage.
      </p>
      <h2>Who can benefit from PRP?</h2>
      <p>
        Our experienced medical supervisor conducts comprehensive evaluations
        when you come in for the management of your neuromuscular or skeletal
        condition. They take a multidisciplinary approach to care, using many
        therapies and treatments aimed at promoting your body’s natural healing
        abilities. They may recommend PRP to promote structural support and
        cushioning of a musculoskeletal injury or degenerative condition as well
        as cosmetic application, such as:
      </p>
      <p>Plantar Fasciitis</p>
      <p>Osteoarthritis</p>
      <p>Tendon tears</p>
      <p>Degenerative Joint Disease</p>
      <p>Hair Restoration</p>
      <p>Skin Rejuvenation</p>
      <p>
        If you’re struggling with a chronic pain condition or your recovery from
        an injury is taking longer than you anticipated, call the office to
        learn more about PRP and how it may benefit you. The medical supervisor
        and Reform Regenerative Health team also use PRP for cosmetic treatments
        such as skin rejuvenation and hair restoration.
      </p>
      <h2>What happens during PRP treatment?</h2>
      <p>
        The details of your PRP treatment depend on your diagnosis and needs.
        The experienced orthopedic surgeon and medical provider and Reform
        Regenerative Health team review the specifics of your therapy at your
        consultation so you know what to expect. Our experienced medical
        supervisor and Reform Regenerative Health team perform your PRP
        treatment at the office. There’s no downtime after injections, however,
        they may suggest activity modification based on your specific diagnosis
        and needs. To learn more about the powers of PRP, call the office for
        more information and book your appointment today.
      </p>
    </div>,
    <div className="Iv-info">
      <p>
        Your body is filled with cells and substances aimed at healing and
        repairing diseased and damaged tissue. However, aging and poor blood
        supply may affect the delivery of your body’s natural healing elements
        to the tissues and organs that need it. Our experienced medical provider
        and orthopedic surgeon, Dr. Michael Duchamp and Reform Regenerative
        Health team in Farmers Branch, Texas, offers Wharton’s Jelly, connective
        tissue therapies that contains stem cells to provide your body with the
        boost it needs for structural support, cushioning, and cosmetic
        aesthetics. Call the office to request an appointment and learn more
        about stem cell treatment.
      </p>
      <h1>IV Therapy</h1>
      <h2>What is IV therapy?</h2>
      <p>
        IV (intravenous) therapy delivers medication, hydration, and nutrients
        directly into your bloodstream. Our experienced medical supervisor and
        Reform Regenerative Health team provide customized IV therapy to address
        several issues. Depending on your needs, they might recommend IV therapy
        to:
      </p>
      <h3>Support healing</h3>
      <p>
        Our experienced medical supervisor and Reform Regenerative Health team
        offer IV therapy to boost your body’s natural healing ability. For
        example, vitamin C and zinc support healthy cell generation. Glutathione
        and magnesium increase your immune system function, reduce inflammation,
        and accelerate recovery.
      </p>
      <h3>Fitness Optimization</h3>
      <p>
        You might also benefit from IV infusions to in fitness training. For
        example, our experienced medical supervisor and Reform Regenerative
        Health team might recommend an infusion to revitalize and hydrate the
        body.
      </p>
      <h3>Enhance overall health</h3>
      <p>
        IVs are also an excellent way to address vitamin and nutrient
        deficiencies that impact your health and well-being. Vitamin B, for
        example, can increase your energy levels and help you feel better
        overall.
      </p>
      <h2>What happens during IV therapy?</h2>
      <p>
        IV therapy is a relaxing treatment. When you come into the office, our
        experienced medical supervisor and Reform Regenerative Health team help
        you settle into a comfortable chair. They insert a needle into your arm
        and connect it to your personalized IV drips. Depending on the type of
        IV therapy, you might spend 30-90 minutes relaxing while receiving your
        infusion. You can read, listen to music, nap, or even catch up on your
        emails during your treatment. You might need a friend or family member
        to drive you home after your IV therapy as some medications might make
        you sleepy. Otherwise, you should be able to get back to most of your
        regular activities almost immediately.
      </p>
      <h2>What are the benefits of IV therapy?</h2>
      <p>
        When you have IV therapy, the treatment enters your bloodstream
        directly. Oral medication has to pass through your body and be absorbed
        in your digestive tract. Inflammation and other issues can prevent your
        body from absorbing everything it needs. IV therapy is also highly
        customizable. Our experienced medical supervisor and Reform Regenerative
        Health team can prescribe the precise dose you need to support healing
        or optimize your health. Many patients report nearly immediate results,
        such as increased energy and pain relief. It can help you recover from
        athletic exertion and strengthen your immune system to prevent illness
        and inflammation. Call the office today to schedule a consultation to
        learn more about IV therapy and how it can help you.
      </p>
    </div>,
    <div className="Hormone-info">
      <p>
        Your body is filled with cells and substances aimed at healing and
        repairing diseased and damaged tissue. However, aging and poor blood
        supply may affect the delivery of your body’s natural healing elements
        to the tissues and organs that need it. Our experienced medical provider
        and orthopedic surgeon, Dr. Michael Duchamp and Reform Regenerative
        Health team in Farmers Branch, Texas, offers Wharton’s Jelly, connective
        tissue therapies that contains stem cells to provide your body with the
        boost it needs for structural support, cushioning, and cosmetic
        aesthetics. Call the office to request an appointment and learn more
        about stem cell treatment.
      </p>
      <h1>Hormone Therapy</h1>
      <h2>What is hormone therapy?</h2>
      <p>
        Hormones are chemicals produced by endocrine glands. They control almost
        everything that happens in your body, from your metabolism and muscle
        mass to your sex drive and energy. You need to maintain a precise
        balance of hormones for optimal body function and health. Many factors,
        however, including aging, diet, and disease, can disrupt your hormone
        balance and cause a variety of disruptive symptoms. Two of the most
        common hormonal imbalances are testosterone and estrogen. These are
        primarily sex hormones, but they also contribute to a multitude of other
        body functions. Our experienced BioTe certified medical supervisor and
        Reform Regenerative Health team offer customized hormone therapy to
        restore your hormone balance and optimize your health and vitality.
      </p>
      <h2>Why would I need hormone therapy?</h2>
      <p>
        Symptoms of a hormonal imbalance vary, depending on the affected hormone
        and the severity of your imbalance. For example, some of the common
        reasons that men might seek testosterone therapy include:
      </p>
      <p>Reduced muscle mass</p>
      <p>Low sex drive</p>
      <p>Erectile dysfunction</p>
      <p>Cognitive Clarity</p>
      <h3>Signs of a hormone imbalance in women include:</h3>
      <p>Vaginal dryness and atrophy</p>
      <p>Painful sex</p>
      <p>Hot flashes and night sweats</p>
      <p>Cognitive Clarity</p>
      <p>Insomnia</p>
      <p>Fatigue</p>
      <p>
        Men and women both experience increased irritability, depression, weight
        gain, and fatigue with a hormone imbalance. If any of these symptoms
        sound familiar, make an appointment to find out if a hormonal imbalance
        is the root cause of your discomfort and get the treatment you need.
      </p>
      <h2>What happens during hormone therapy?</h2>
      <p>
        Our experienced BioTe certified medical supervisor and Reform
        Regenerative Health team begin with a comprehensive consultation. They
        review your symptoms, medical history, and lifestyle, and ask about how
        your symptoms affect your life. They also provide a thorough physical
        exam and lab testing to check for signs of other diseases that could
        cause similar symptoms. The blood test measures your hormone levels to
        identify any imbalances. If a hormone imbalance, like low testosterone
        or low estrogen, is the cause of your symptoms, our experienced BioTe
        certified medical supervisor and Reform Regenerative Health team
        prescribe hormone therapy. Hormone therapy is available through natural
        derived pellet insertion and natural nutraceuticals. You have frequent
        appointments when you start hormone therapy. Our experienced BioTe
        certified medical supervisor and Reform Regenerative Health team need to
        monitor your hormone levels and symptoms. If necessary, they can adjust
        your prescription to optimize your health.
      </p>
      <h2>Is hormone therapy safe?</h2>
      <p>
        Yes. Advances in medicine have made hormone therapy safe and effective.
        However, you still need to check in occasionally with our experienced
        BioTe certified medical supervisor and Reform Regenerative Health team
        for follow up to monitor your health. For example, you should have blood
        tests at least once every six months to ensure you maintain your hormone
        balance and health. If you have signs of a hormone imbalance and are
        interested in hormone therapy, call the office to make an appointment
        today.
      </p>
    </div>,
  ];

  const handleClick = (index) => {
    setSelectedSquare(informationPopup[index]);

    setTimeout(() => {
      infoBoxRef.current &&
        infoBoxRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <div className="Grid-text-all">
      <div className="grid">
        {[...Array(9)].map((_, index) => (
          <div
            key={index}
            className="square"
            style={{
              backgroundImage: backgrounds[index],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            onClick={() => handleClick(index)}
          >
            <span className="square-text">{gridTexts[index]}</span>
          </div>
        ))}
      </div>
      <h2 className="grid-title">Regenerative Medicine Services</h2>
      <div className="grid">
        {[...Array(4)].map((_, index) => (
          
          <div
            key={index + 9} 
            className="square"
            style={{
              backgroundImage: backgrounds[index + 9],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            onClick={() => handleClick(index + 9)}
          >
            <span className="square-text">{gridTexts[index + 9]}</span>
          </div>
        ))}
      </div>
      {selectedSquare && (
        <div className="info-box" ref={infoBoxRef}>
          {selectedSquare}
        </div>
      )}
    </div>
  );
}
export default Squaregrid;
