import "./App.css";
import Locator from "./Googlemaps";
import Slideshow from "./Slideshow";

function Homeapp() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Greetings-Home">
          <h1>Welcome to Reform Ortho & Sports Rehab</h1>
          <h3 className="slogan">
            Renew your body, Restore your life, Reform yourself!
          </h3>
        </div>
        <div className="slideshowmain">
        <Slideshow />
        </div>
      </header>
      <div className="row">
        <div className="span6 right">
          <div className="inner">
            <h4>Accepted Insurance</h4>
          </div>
        </div>
        <div className="provider-imgs count8">
          <figure>
            <img
              src="images/2575947.png"
              className=""
              width="106"
              height="63"
              alt="*Many more options available"
              title="*Many more options available"
              id="insurance-4813"
            />
          </figure>
          <figure>
            <img
              src="images/1771751.png"
              className=""
              width="106"
              height="63"
              alt="Aetna"
              title="Aetna"
              id="insurance-1194"
            />
          </figure>
          <figure>
            <img
              src="images/2272134.png"
              className=""
              width="106"
              height="63"
              alt="Blue Cross Blue Shield"
              title="Blue Cross Blue Shield"
              id="insurance-64"
            />
          </figure>
          <figure>
            <img
              src="images/1779359.png"
              className=""
              width="106"
              height="63"
              alt="Golden Rule"
              title="Golden Rule"
              id="insurance-229"
            />
          </figure>
          <figure>
            <img
              src="images/2019544.png"
              className=""
              width="106"
              height="63"
              alt="GPA"
              title="GPA"
              id="insurance-3387"
            />
          </figure>
          <figure>
            <img
              src="images/1771774.png"
              className=""
              width="106"
              height="63"
              alt="Medicare"
              title="Medicare"
              id="insurance-1477"
            />
          </figure>
          <figure>
            <img
              src="images/1771875.png"
              className=""
              width="106"
              height="63"
              alt="UMR"
              title="UMR"
              id="insurance-616"
            />
          </figure>
          <figure>
            <img
              src="images/1771716.png"
              className=""
              width="106"
              height="63"
              alt="United Healthcare"
              title="United Healthcare"
              id="insurance-1138"
            />
          </figure>
        </div>
        <p>
          We accept many insurance plans. If you do not see your insurance plan
          listed, please call our office for more information.
        </p>
      </div>

      <div className="Content">
        <div className="ImageContainer">
          {/* Replace with your desired image */}
          <img src="/images/ROhours.png" alt="Reform Ortho" />
        </div>
        <Locator />
      </div>
    </div>
  );
}

export default Homeapp;
