import React, { useState, useEffect } from "react";

const slidesData = [
  "images/2001205.png",
  "images/2001209.png",
  "images/2001211.png",
  "images/2004201.jpg",
  "images/2004202.jpg",
];

function Slideshow() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 4000);

    return () => {
      clearInterval(interval); 
    };
  }, []);

  const goToNextSlide = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1
      );
      setIsTransitioning(false);
    }, 1000); 
  };

  return (
    <div className="slideshow">
      <div className={`slide ${isTransitioning ? "transitioning" : ""}`}>
        <img src={slidesData[currentSlide]} alt={`Slide ${currentSlide + 1}`} />
      </div>
    </div>
  );
}
export default Slideshow;
