import React, { useState, useEffect } from "react";
import { firestore } from "./Firebase";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import './App.css';

function ReviewList() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStarRating = (rating) => {
    let stars = "";
    for (let i = 0; i < rating; i++) {
      stars += "★"; // Unicode filled star
    }
    for (let i = rating; i < 5; i++) {
      stars += "☆"; // Unicode empty star
    }
    return <span className="Gold-stars">{stars}</span>;
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Create a query against the collection
        const reviewsQuery = query(
          collection(firestore, "Reform Ortho"),
          orderBy("Date", "desc"),
          limit(25)
        );

        // Execute the query
        const querySnapshot = await getDocs(reviewsQuery);
        const reviewsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const date = data["Date"] ? data["Date"].toDate() : new Date();
          const formattedDate = `${
            date.getMonth() + 1
          }/${date.getDate()}/${date.getFullYear()}`;
          return {
            id: doc.id,
            date: formattedDate,
            firstName: data["First Name"],
            lastName: data["Last Name"],
            rating: data["Rating"],
            reviewText: data["Review Text"],
          };
        });
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return <p>Loading reviews...</p>;
  }
  return (
    <div>
      {reviews.map((review) => (
        <div key={review.id} className="Reviewsbox">
          <div className="Reviewheader">
            <div className="Review-info">
              <span className="review-name">
                {review.firstName}{" "}
                {review.lastName ? review.lastName[0] + "." : ""}
              </span>
              <span className="review-date">{review.date}</span>
              <span className="review-rating">
                Rating: {getStarRating(review.rating)}
              </span>
            </div>
            <span className="review-text">{review.reviewText}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ReviewList;
