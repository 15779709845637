import './App.css';
import ContactForm from "./Contactform";

function Contactapp() {
  return (
    <div className="Contact-Main">
      <div>
        <ContactForm />
      </div>
    </div>
  );
}

export default Contactapp;
