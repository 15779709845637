import './App.css';
import Squaregrid from './Squaregrid';



function Servicesapp() {
  return (
    <div className="Serviceapp-container">
      <div className="Services-Intro">
        <h1>Click To Learn More</h1>
      </div>
      <div className="Squaregrid9-Container">
        <Squaregrid />
      </div>
      
    </div>
  );
}

export default Servicesapp;