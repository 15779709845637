import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyCpCDhl6LuKoDi3spip8td9ptsXToyMEpw",
    authDomain: "reform-website-d76cb.firebaseapp.com",
    projectId: "reform-website-d76cb",
    storageBucket: "reform-website-d76cb.appspot.com",
    messagingSenderId: "1573642439",
    appId: "1:1573642439:web:e7140d3a0b54994cc9dee2",
    measurementId: "G-225FVLGS6Q"
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);
const functions = getFunctions(app);

export { app, functions, firestore, httpsCallable };